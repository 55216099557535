#awards {
    padding: 2rem;
    text-align: center;
    background: linear-gradient(135deg, #6e8efb, #a777e3);
    color: #fff;
    position: relative;
  }
  
  #awards h2 {
    margin-top: -10px;
    font-size: 3rem;
    margin-bottom: 2rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    color: white;
  }
  
  .awards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    padding: 1rem;
  }
  
  @media (min-width: 768px) {
    .awards-grid {
      grid-template-columns: repeat(4, 1fr); /* 4 awards per row on larger screens */
    }
  }
  
  .award-card {
    background-color: #fff;
    color: #333;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .award-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .award-icon {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  
  .award-content {
    text-align: left;
  }
  
  .award-content h3 {
    margin: 0;
    font-size: 1.5rem;
  }
  
  .award-year {
    font-size: 1rem;
    color: #666;
  }
  
  .award-content p {
    margin: 0.5rem 0 0;
    font-size: 1rem;
    line-height: 1.4;
  }
  