#projects {
    padding: 2rem;
    text-align: center;
    background: linear-gradient(135deg, #6e8efb, #a777e3);
    
  }
  
  #projects h2 {
    margin-top: -10px;
    font-size: 3rem;
    margin-bottom: 2rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    color: white;
  }
  
  .project-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .project-card {
    background-color: #fff;
    color: #333;
    border-radius: 10px;
    padding: 1rem;
    margin: 1rem;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    text-align: left;
  }
  
  .project-card:hover {
    transform: translateY(-10px);
  }
  
  .project-card img {
    width: 100%;
    border-radius: 10px;
  }
  
  .project-card h3, .project-card h4 {
    margin-top: 0.5rem;
  }
  
  .project-card p {
    margin: 1rem 0;
  }
  
  .project-card a {
    display: inline-block;
    margin-top: 1rem;
    color: #0d6efd;
    text-decoration: none;
  }
  
  .project-card a:hover {
    text-decoration: underline;
  }
  