.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background: linear-gradient(135deg, #333, #555);
    color: #fff;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .navbar h1 {
    margin: 0;
    font-size: 1.8rem;
    font-weight: bold;
    background: -webkit-linear-gradient(#fff, #ddd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .navbar-right {
    display: flex;
    align-items: center;
  }
  
  .navbar-right a {
    margin-left: 1.5rem;
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
  }
  
  .navbar-right a:hover {
    color: #0d6efd;
    transform: scale(1.1);
    cursor: pointer;
  }
  
  .navbar-right a svg {
    margin-left: 0.5rem;
    font-size: 1.5rem;
  }
  
  .navbar-right a:first-child {
    margin-left: 0;
  }
  