#about {
    padding: 4rem 2rem;
    text-align: center;
    background: linear-gradient(135deg, #6e8efb, #a777e3);
    color: #fff;
    height: 500px;
  }
  
  #about h2 {
    margin-top: -20px;
    font-size: 3rem;
    margin-bottom: 2rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .about-content {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
  }
  
  .about-image {
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .about-image img {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    object-fit: cover;
    /* object-position: top; */
    object-position: top;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, opacity 0.3s ease;
    
    z-index: 2; 
    transform: translateY(10%);
  }
  
  .about-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
    padding: 2rem;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: 1;
    text-align: center;
  }
  
  .about-image:hover img {
    transform: scale(0.9);
    opacity: 0.5;
  }
  
  .about-image:hover .about-info {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.05);
  }
  
  .about-info h3 {
    margin-top: 0;
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .about-info p {
    margin: 0.5rem 0;
    font-size: 1rem;
    color: #eee;
  }
  
  @media (min-width: 768px) {
    .about-content {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  
    .about-image {
      margin: 0 2rem;
    }
  
    .about-info {
      width: 600px;
      padding: 3rem;
    }
  }
  