#research {
    padding: 2rem;
    text-align: center;
    background: #f7f7f7;
  }

#research h2{
    margin-top: -10px;
    font-size: 3rem;
    margin-bottom: 2rem;
    color: black;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
  
  .research-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .research-card {
    background-color: #fff;
    color: #333;
    border-radius: 10px;
    padding: 1rem;
    margin: 1rem;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .research-card:hover {
    transform: translateY(-10px);
  }
  
  .research-card h3 {
    margin-top: 0;
  }
  