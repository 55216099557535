#education {
    padding: 2rem;
    text-align: center;
    /* background: linear-gradient(135deg, #6e8efb, #a777e3); */
    background-color: #f7f7f7;;
    color: #333;
    position: relative;
  }
  
  #education h2{
    margin-top: -10px;
    font-size: 3rem;
    margin-bottom: 2rem;
    color: black;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
  
  
  .education-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .education-logo {
    position: relative;
    z-index: 1;
    
  }
  
  .education-logo img {
    width: 155px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    transition: transform 0.5s ease, border-radius 0.4s ease;
  }
  
  .education-info {
    background-color: #fff;
    color: #333;
    border-radius: 10px;
    height: 112px; /* Match the height of the logo */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 50%;
    /* left: 160px; */
    transform: translateY(-51%);
    transform: translate(50px, -51%);
    width: 0; /* Start with 0 width */
    overflow: hidden;
    white-space: nowrap;
    transition: width 0.5s ease, opacity 0.5s ease;
    display: flex;
    align-items: center;
    opacity:0;
    padding: 1rem;
  }
  
  .education-container:hover .education-logo img {
    transform: translateX(-280px);
    border-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  
  .education-container:hover .education-info {
    width: 500px; /* Width when hovered */
    opacity: 1;
  }
  
  .info-content {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  .education-container:hover .info-content {
    opacity: 1;
  }
  
  .education-info h3 {
    margin: 0;
    color: #6e8efb;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .education-info p {
    margin: 0.5rem 0;
    font-size: 1rem;
    color: #555;
  }
  
  .education-info {
    background: linear-gradient(135deg, #f0f0f0, #ffffff);
  }
  
  .education-info::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 10px;
    height: 100%;
    background-color: #ffffff;
    transform: translateY(-50%);
  }
  
  .info-content {
    padding-left: 20px;
  }
  .education-line {
    width: 2px;
    height: 50px;
    background-color: black;
    margin: 1rem 0;
  }

  .education-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }